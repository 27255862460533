/* imports */
@import "/assets/css/webfonts/urwdin.css";
/* color definitions */
/* fonts */
.dinblack,
.ext-accordion-headline1,
.ext-accordion-headline2 {
  font-family: "urwdin-black", "Helvetica", "Arial", "sans-serif";
}
.dinreg {
  font-family: "urwdin-regular", "Helvetica", "Arial", "sans-serif";
}
/* Allgemeines */
body {
  font-family: "urwdin-regular", "Helvetica", "Arial", "sans-serif";
  font-size: 1rem;
  line-height: 1.2rem;
  color: #494949;
}
a {
  text-decoration: none;
}
a.mainLink:link,
a.mainLink:visited,
a.mainLink:active {
  color: #65b32e;
  text-decoration: none;
}
a.whiteLink:link,
a.whiteLink:visited,
a.whiteLink:active {
  color: #fff;
  text-decoration: none;
}
/* Anker verschieben */
a.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}
.btn.btn-primary {
  background-color: #65b32e;
  border-color: #65b32e;
}
.btn.btn-primary:hover {
  background-color: #5b9b1e;
  border-color: #5b9b1e;
}
.text-primary {
  color: #65b32e !important;
}
.text-green {
  color: #5b9b1e !important;
}
.text-greyblue {
  color: #293d4f !important;
}
.text-white {
  color: #fff !important;
}
.text-darkgrey {
  color: #494949 !important;
}
.hover_me {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: block;
  cursor: pointer;
}
.show_me {
  opacity: 0;
  background-color: #55a91b;
  color: #fff;
  border-radius: 20px;
  height: 100%;
  width: 100%;
}
.hover_me:hover .remove_me {
  opacity: 0;
}
.hover_me:hover .show_me {
  opacity: 2;
}
.remove_me {
  opacity: 2;
}
.toggled {
  position: absolute;
  transition: opacity 301ms;
}
#hero .container {
  background: url("/assets/images/header_mobile.jpg") bottom / cover no-repeat;
  height: 800px;
  position: relative;
}
#heroLadepunkt .container {
  background: url("/assets/images/hero-lp-mobile-min.jpg") bottom / cover no-repeat;
  height: 800px;
  position: relative;
}
.minHeight180 {
  min-height: 180px;
}
.minHeight160 {
  min-height: 160px;
}
@media only screen and (min-width: 992px) {
  #hero .container {
    background: url("/assets/images/header.jpg") bottom / cover no-repeat;
    height: 450px;
  }
  #heroLadepunkt .container {
    background: url("/assets/images/hero-lp-min.jpg") bottom / cover no-repeat;
    height: 450px;
  }
}
.headLineSize,
.ext-accordion-headline1,
.ext-accordion-headline2 {
  font-size: 2rem;
  line-height: 2.2rem;
}
/* Pricetag */
.pricetag {
  background: #65b32e;
  width: 230px;
  height: 230px;
  border-radius: 300px;
  position: absolute;
  bottom: -115px;
  left: 50px;
  z-index: 1;
  margin: auto;
}
.pricetag .price {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 4rem;
  line-height: 4rem;
}
.pricetag .description {
  color: #fff;
}
/* störer */
.stoerer_grau {
  background: #3d3d3d;
  width: 190px;
  height: 190px;
  border-radius: 300px;
  position: absolute;
  top: -85px;
  right: 180px;
  z-index: 1;
  margin: auto;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 22px;
}
.mrd_logo {
  width: 250px;
}
/* section praemie */
section#funktion .heading {
  margin-top: 8rem;
}
/* section flow */
section#flow .card .card-body h4 {
  border-bottom: 3px solid #57aa1c;
  background: #55a91b;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #fff;
  font-size: 1.2rem;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 60px;
}
/* Accordions */
.accordionBack {
  background: url(/assets/images/accordion-bg.jpg);
  background-size: cover;
  background-position: center top;
}
.accordionBackLadepunkt {
  background: url(/assets/images/accordion-bg.jpg);
  background-size: cover;
  background-position: center top;
}
.ext-accordion-headline1 {
  color: #494949;
}
/* Accordions */
.ext-accordion-headline2 {
  color: #65b32e;
}
.ext-accordion-button {
  color: #494949 !important;
}
/* Footer */
.footerContainer {
  background-color: #65b32e;
  color: #fff;
  font-size: 0.9rem;
}
/* booking expandable and modal booking form */
#fixed-sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 2;
}
.sideBarElement {
  cursor: pointer;
  width: auto;
  height: auto;
  padding: 1rem;
  box-shadow: 5px 5px 5px -5px #000000;
  background-color: #65b32e;
}
.sideBarElement:hover {
  background-color: #3d3d3d;
}
/* booking expandable and modal booking form */
#fixed-bookingBtn {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 2;
  cursor: pointer;
  width: auto;
  height: auto;
  background-color: #65b32e;
  color: #fff;
  padding: 1rem;
  box-shadow: 5px 5px 5px -5px #000000;
}
#fixed-bookingBtn:hover {
  background-color: #3d3d3d !important;
}
.modal-dialog {
  max-width: 50% !important;
}
.btn-book {
  background-color: #65b32e;
  color: #fff;
}
.bookingBtnLink {
  color: #fff !important;
}
.info-banner {
  background-color: #65b32e;
  font-size: 2rem;
  line-height: 2.2rem;
}
